<template>
  <div class="w-full" v-if="tag">
    <h1 class="text-3xl font-bold capitalize">{{ tag.label }}</h1>
    <table class="w-full">
      <tbody>
        <tr v-for="structure in mappedTag" :key="structure.id" class="border-b">
          <td>
            <h1>{{ structure.title }}</h1>
            <h4>{{ structure.owner }}</h4>
          </td>

          <td>{{ structure.address }}</td>
          <td>
            {{ structure.status }}
          </td>
          <td>{{ structure.type }}</td>
          <td>
            <v-switch
              :value="structure.highlight"
              :input-value="structure.highlight"
              label="highlight"
              disabled
              inset
            ></v-switch>
          </td>
          <td>
            <v-btn icon :to="`/properties/${structure.id}`"
              ><v-icon>link</v-icon></v-btn
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TagEdit',
  data() {
    return {
      tag: null
    }
  },
  async created() {
    this.tag = await this.$store.dispatch('tags/getTag', this.$route.params.id)
    //console.log("tags ",tags,this.$route.params)
  },
  computed: {
    mappedTag() {
      //console.log("Tag Computed", this.tag);
      return this.tag.structures.items.map(s => s.structure)
    }
  }
}
</script>

<style scoped lang="postcss">
h1 {
  @apply font-bold text-xl capitalize;
}
h4 {
  @apply text-gray-500 tracking-wider uppercase text-sm;
}
table > tbody > tr {
  vertical-align: baseline;
}
</style>
